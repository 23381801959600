import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div style={{
        minWidth:"100vw",
        minHeight:"100vh",
        backgroundColor: "#7fffd4",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "left",
        flexWrap: "wrap",

      }}/>
  </div>
  );
}

export default App;
